<template>
  <CFooter class="px-4">
    <div>
      <span class="ms-1">{{ $t("common.text.copyright") }}</span>
    </div>
    <div class="footer-locale-selector">
      <LocaleSelector class="mx-3" />
      <div
        v-for="(stipulation, stipulationIndex) in stipulations"
        :key="'stipulation-' + stipulationIndex"
        class="d-flex justify-content-between stipulation"
      >
        <a class="clickable" @click="showStipulation(stipulation, $event)">{{
          stipulation.title
        }}</a>
      </div>
    </div>
    <div class="ms-auto">
      <span v-if="devModeStore.getDevMode !== 'none'" class="dev-mode">
        {{ $t("devmode.text.mode_" + devModeStore.getDevMode) }}
      </span>
      <span class="me-1">Powered by</span>
      <a href="https://softminds.net">Softminds</a>
      <div v-if="currentEnvironment !== 'production'" class="ribbon">
        {{ $t("env.nm." + currentEnvironment) }}
      </div>
    </div>
  </CFooter>
</template>
<script setup lang="ts">
import { CFooter } from "@coreui/vue/dist/esm/components/footer";
import { onMounted } from "vue";
import LocaleSelector from "~/components/controls/LocaleSelector.vue";
import { useDevModeStore } from "~/stores/devMode";
import { customFetch } from "~/composables/customFetch";
import { useLocaleStore } from "~/stores/locale";
import { useModalStore } from "~/stores/modal";
const config = useRuntimeConfig();
const currentEnvironment = ref(config.public.env);
const devModeStore = useDevModeStore();
const stipulations = ref();
const modalStore = useModalStore();
const showStipulation = (stipulation) => {
  modalStore.openModal({
    content: stipulation.cn,
    size: "lg",
    titleHtml: "<h2>" + stipulation.title + "</h2>",
    okButtonOnly: true,
  });
};
onMounted(async () => {
  const response: any = await customFetch(
    "/stipulation/public/stipulation?stplatTyCode=stipulation_type.registration",
    {
      method: "GET",
    },
  );
  stipulations.value = response.data;
});

const localeStore = useLocaleStore();
watch(
  () => localeStore.getLocale,
  async () => {
    const response: any = await customFetch(
      "/stipulation/public/stipulation?stplatTyCode=stipulation_type.registration",
      {
        method: "GET",
      },
    );
    stipulations.value = response.data;
  },
);
</script>
<style scoped>
.ribbon {
  position: fixed;
  bottom: 10px;
  right: -30px;
  width: 200px;
  height: 40px;
  background: crimson;
  color: white;
  text-align: center;
  line-height: 40px;
  font-weight: bold;
  font-size: 16px;
  transform: rotate(-30deg);
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
}
.dev-mode {
  margin-right: 1rem;
  color: crimson;
  font-weight: bold;
}
.footer-locale-selector {
  display: flex;
}
.footer-locale-selector >>> .form-select {
  width: auto !important;
}
.footer-locale-selector .stipulation {
  white-space: nowrap;
  margin-left: 2rem;
  align-items: center !important;
}
</style>
