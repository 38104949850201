import { defineComponent, h, onMounted, ref, resolveComponent } from "vue";

import {
  CBadge,
  CSidebarNav,
  CNavItem,
  CNavGroup,
  CNavTitle,
} from "@coreui/vue";
import simplebar from "simplebar-vue";
import "simplebar-vue/dist/simplebar.min.css";
import { CIcon } from "@coreui/icons-vue";
import NuxtLink from "#app/components/nuxt-link.js";
import { useNuxtApp } from "#app";
import { useMenusStore } from "~/stores/menu.js";
import { useTabStore } from "~/stores/tab.js";
import { useModalStore } from "~/stores/modal.js";

const AppSidebarNav = defineComponent({
  name: "AppSidebarNav",
  components: {
    CNavItem,
    CNavGroup,
    CNavTitle,
  },
  setup() {
    const firstRender = ref(true);

    onMounted(() => {
      firstRender.value = false;
    });
    const route = useRoute();

    const renderItem = (item, parentPath, depth) => {
      item.items = item.children;
      if (!parentPath) {
        parentPath = "";
      }
      parentPath += "/";
      if (!depth) {
        depth = 0;
      }
      depth++;
      if (item.items) {
        item.component = "CNavGroup";
        return h(
          CNavGroup,
          {
            as: "div",
            class: "ps-" + depth * 2,
            compact: true,
          },
          {
            togglerContent: () => [
              h(CIcon, {
                customClassName: "nav-icon",
                name: item.icon,
              }),
              item.name,
            ],
            default: () =>
              item.items.map((child) =>
                renderItem(child, parentPath + item.alias),
              ),
          },
        );
      } else {
        item.component = "CNavItem";
        item.to = parentPath + item.alias;
      }
      return item.to
        ? h(
            NuxtLink,
            {
              to: {},
              onClick: (e) => {
                e.preventDefault(); // 기본 경로 이동 막기
                e.stopPropagation();

                const tabStore = useTabStore();
                for (let i = 0; i < tabStore.getTabs.length; i++) {
                  const tab = tabStore.getTabs[i];
                  if (tab.path === item.to) {
                    tabStore.setSelectedTab(tab);
                    navigateTo(tab.fullPath);
                    return;
                  }
                }
                const router = useRouter();
                const routes = router.getRoutes();
                let foundRoute;
                for (let i = 0; i < routes.length; i++) {
                  if (
                    routes[i].path === item.to ||
                    routes[i].path + "/" === item.to
                  ) {
                    foundRoute = item.to;
                    break;
                  }
                }
                if (foundRoute) {
                  navigateTo(item.to);
                } else {
                  const modalStore = useModalStore();
                  modalStore.openModal({
                    content: useNuxtApp().vueApp.$i18n?.global.t(
                      "menu.text.no_module",
                    ),
                  });
                }
              },
            },
            {
              default: (props) =>
                h(
                  resolveComponent(item.component),
                  {
                    active: item.to === route.path,
                    as: "div",
                    class: "ps-" + depth * 2,
                    href: props.href,
                  },
                  {
                    default: () => [
                      item.icon
                        ? h(CIcon, {
                            customClassName: "nav-icon",
                            name: item.icon,
                          })
                        : h(
                            "span",
                            { class: "nav-icon" },
                            h("span", { class: "nav-icon-bullet" }),
                          ),
                      item.name,
                      item.badge &&
                        h(
                          CBadge,
                          {
                            class: "ms-auto",
                            color: item.badge.color,
                          },
                          {
                            default: () => item.badge.text,
                          },
                        ),
                    ],
                  },
                ),
            },
          )
        : h(
            resolveComponent(item.component),
            {
              as: "div",
            },
            {
              default: () => item.name,
            },
          );
    };
    const menusStore = useMenusStore();
    const nav = menusStore.getMenus;
    return () =>
      h(
        CSidebarNav,
        {
          as: simplebar,
        },
        {
          default: () => (nav ? nav.map((item) => renderItem(item)) : []),
        },
      );
  },
});

export { AppSidebarNav };
