<template>
  <div v-if="userStore.getUser">
    <Modal />
    <AppSidebar />
    <div class="wrapper d-flex flex-column min-vh-100">
      <AppHeader />
      <div class="body flex-grow-1">
        <CContainer class="px-4 no-max-width body-container">
          <div class="select-area">
            <CFormSelect
              :value="
                tabStore.getSelectedTab
                  ? tabStore.getSelectedTab.path
                  : undefined
              "
              @change="onSelectedTab($event)"
            >
              <option
                v-for="tab in tabStore.tabs"
                :key="tab.path"
                :value="tab.path"
              >
                {{
                  tab.name
                    ? tab.nameIsNotI18n
                      ? toPascalCase(tab.name)
                      : $t(tab.name)
                    : ""
                }}
              </option>
            </CFormSelect>
          </div>
          <CTabs :active-item-key="1" class="tab-area">
            <CTabList variant="tabs">
              <CTab
                v-for="(tab, tabIndex) in tabStore.getTabs"
                :key="'tab-' + tabIndex"
                :class="{
                  active: tab.path === route.path,
                  inactive: tab.path !== route.path,
                }"
                :item-key="tab.path"
                @click="selectTab(tab)"
              >
                <CIcon :name="tab.icon" class="nav-icon" />&nbsp;
                {{
                  tab.name
                    ? tab.nameIsNotI18n
                      ? toPascalCase(tab.name)
                      : $t(tab.name)
                    : ""
                }}
                &nbsp;
                <CCloseButton
                  v-if="tab.closable"
                  class="close-button"
                  @click="closeTab($event, tab)"
                />
              </CTab>
              <CDropdown v-if="closableTabExists()">
                <CDropdownToggle :caret="true"> </CDropdownToggle>
                <CDropdownMenu>
                  <CDropdownItem role="button" @click="closeAllTabs($event)">
                    <CIcon icon="cil-close" />
                    {{ $t("common.button.close_all_tabs") }}
                  </CDropdownItem>
                </CDropdownMenu>
              </CDropdown>
            </CTabList>
          </CTabs>
          <div class="tab-contents">
            <div
              class="close-button-area float-end"
              @click="closeTab($event, tabStore.getSelectedTab)"
            >
              <CCloseButton
                v-if="
                  tabStore.getSelectedTab && tabStore.getSelectedTab.closable
                "
                class="close-button"
              />
            </div>
            <div class="breadcrumb-area">
              <AppBreadcrumb class="clear" />
            </div>
            <div class="clear">
              <slot />
            </div>
          </div>
        </CContainer>
      </div>
      <AppFooter />
    </div>
  </div>
</template>
<script setup lang="ts">
import { CContainer } from "@coreui/vue/dist/esm/components/grid";
import { CTab, CTabList, CTabs } from "@coreui/vue/dist/esm/components/tabs";
// eslint-disable-next-line import/no-named-as-default
import CIcon from "@coreui/icons-vue";
import { CFormSelect } from "@coreui/vue/dist/esm/components/form";
import { CCloseButton } from "@coreui/vue-pro/dist/esm/components/close-button";
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/vue/dist/esm/components/dropdown/index";
import { useI18n } from "vue-i18n";
import AppSidebar from "~/components/AppSidebar.vue";
import { useTabStore } from "~/stores/tab";
import { useModuleStore } from "~/stores/module";
import { useUserStore } from "~/stores/user";
import { useModalStore } from "~/stores/modal";
// 메뉴의 경로는 이미 불려온 상태이므로, 현재 경로가 로컬 스토리지에 저장된 메뉴들 경로 중 있는지 확인을 한다.
const route = useRoute();
const tabStore = useTabStore();
const moduleStore = useModuleStore();
const userStore = useUserStore();

function closableTabExists() {
  for (let i = 0; i < tabStore.getTabs.length; i++) {
    if (tabStore.getTabs[i].closable) {
      return true;
    }
  }
  return false;
}

function selectTab(tab: object) {
  tabStore.setSelectedTab(tab);
  navigateTo(tab.fullPath);
}

function onSelectedTab(e: any) {
  nextTick(() => {
    for (let i = 0; i < tabStore.getTabs.length; i++) {
      const tab = tabStore.getTabs[i];
      if (tab.path === e.target.value) {
        selectTab(tab);
        break;
      }
    }
  });
}
const modalStore = useModalStore();
const { t } = useI18n();
function closeAllTabs(e: any) {
  modalStore.openModal({
    content: t("common.modal.text.close_all_tabs_confirm"),
    onOk: () => {
      for (let i = tabStore.getTabs.length - 1; i >= 0; i--) {
        const tab = tabStore.getTabs[i];
        if (tab.closable) {
          closeTab(e, tab, false);
        }
      }
      navigateTo("/");
    },
  });
}

function closeTab(e: object, tab: object, navigate: boolean = true) {
  if (e) {
    e.preventDefault();
    e.stopPropagation();
  }
  const tabs = tabStore.getTabs;
  const index = tabs.findIndex((paramTab) => tab.path === paramTab.path);
  if (index !== -1) {
    tabs.splice(index, 1);
  }
  tabStore.setTabs(tabs);
  moduleStore.removeModuleStack(tab.moduleId);
  localStorage.openedTabs = JSON.stringify(tabStore.getTabs);
  if (localStorage.visitedRoutes) {
    const routes = JSON.parse(localStorage.visitedRoutes);
    for (let i = routes.length - 1; i >= 0; i--) {
      if (routes[i].path === tab.path) {
        routes.splice(i, 1);
      }
    }
    localStorage.visitedRoutes = JSON.stringify(routes);
    if (navigate) {
      if (routes.length) {
        navigateTo(routes[routes.length - 1].fullPath);
      } else {
        navigateTo("/");
      }
    }
  }
}
// 메뉴는 MDI 형태로 열리며, MDI는 로컬 스토리지에 저장된다.
// 항상 현재 상태는 URL로 표현된다.
</script>

<style scoped>
.no-max-width {
  max-width: none !important;
}
.tab-contents {
  padding: 1rem;
}
.breadcrumb-area {
  margin-bottom: 1rem;
}
.select-area {
  display: none;
}
.tab-area {
  display: block;
  position: sticky;
  top: 58px;
  z-index: 1019;
  backdrop-filter: blur(20px); /* 블러 효과 */
  margin: -1.5rem -1.5rem 0 -1.5rem;
  padding: 1.5rem 1.5rem 0 1.5rem;
}
.tab-area >>> .nav-link.inactive {
  color: var(--cui-nav-link-color);
  text-decoration: none;
  background: none;
  border: 0;
}
.close-button-area {
  display: none;
  cursor: pointer;
  padding: 0.2rem 0 0.2rem 1rem;
}
@media (max-width: 575px) {
  .tab-area {
    display: none;
  }
  .select-area {
    display: block;
  }
  .close-button-area {
    display: block;
  }
}
.close-button {
  font-size: 0.75rem;
}
.body-container {
  overflow: visible;
}
</style>
